import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Link } from "gatsby"
import { getYouTubeEmbedUrl, isYoutubeUrl } from "../../../../utils/gatsby-helpers"


const BlogImage = ({ image, title = null, alt, figcaption = null, link = null, blank = null }) => {
    const [videoModal, setVideoModal] = useState(false)

    const img = <div className="container__content__image">
        <GatsbyImage alt={alt} image={getImage(image)} title={title} />
        {figcaption &&
            <p>{figcaption}</p>
        }
    </div>

    const openVideoModal = () => {
        setVideoModal(!videoModal)
    }

    if (link) {
        if (isYoutubeUrl(link)) {
            return <>
                <a onClick={openVideoModal}>{img}</a>
                {videoModal &&
                    <div className="tc-modal">
                        <div className="tc-modal__content">
                            <div className="tc-modal__content__header">
                                <button className="button-icon button-icon--sm" onClick={openVideoModal}>
                                    <i className="icon-close"></i>
                                </button>
                            </div>
                            <div className="tc-modal__content__body">
                                <iframe
                                    loading="lazy"
                                    src={getYouTubeEmbedUrl(link)}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                />
                            </div>
                        </div>
                    </div>
                }
            </>
        }

        if (blank) {
            return <a href={link} target="_blank">{img}</a>
        }

        return <Link to={link}>{img}</Link>
    }


    return (img)
}

export default BlogImage
