import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"

const BlogCta = ({ data, image }) => {

    return (
        <div className="tile tile--blog-content">
            {data.image && 
                <div className="tile__image">
                    <GatsbyImage alt={data.alternativeText} image={getImage(image)} />
                </div>
            }
            <div className="tile__body">
                <div className="tile__body__text">
                    {data.name && 
                        <h3>{data.name}</h3>
                    }
                    {data.description && 
                        <ReactMarkdown children={data.description} rehypePlugins={[rehypeRaw]}/>
                    }
                    {!data.blank && 
                        <Link to={data.url}>
                            <button className="button">{data.button}</button>
                        </Link>
                    }
                    {data.blank && 
                        <a href={data.url} target="_blank" rel="noreferrer">
                            <button className="button"> {data.button}</button>
                        </a>
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogCta

