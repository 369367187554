import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { initializeHubspotForm } from "../../../utils/gatsby-helpers"
import { graphql, useStaticQuery } from "gatsby"

const Newsletter = ({ lang = "en" }) => {
    const query = useStaticQuery(graphql`query {
        blog: allMarkdownRemark( filter: { frontmatter: { name: { eq: "blog" } } } ) {
            edges {
                node {
                    frontmatter {
                        url
                        subscribe
                        see_more
                        see_less
                        view_more
                        recommended
                        explore
                        newsletter {
                            title
                            description
                        }
                        lang
                    }
                }
            }
        }
    }`);
    const blog = query.blog.edges.filter((item) => item.node.frontmatter.lang === lang)[0].node.frontmatter

    const form = {
        "en": {
            region: "na1",
            portalId: "20249583",
            formId: "4ccd2e79-9836-4b03-a50a-25389d57a194"
        },
        "es": {
            region: "na1",
            portalId: "20249583",
            formId: "a36d099d-9a65-4224-b3ca-337dfb87a90e"
        }
    }

    initializeHubspotForm(form[lang].portalId, form[lang].formId, "#footer-form-container", form[lang].region)

    return <div className="main__section main__section--100" data-aos="zoom-in">
        <div className="container">
            <div className="tile tile--white">
                <StaticImage
                    src={"../../../new/resources/home/newsletter.svg"}
                    alt="Newsletter icon"
                />
                <h4>{blog.newsletter.title}</h4>
                <div id="footer-form-container" className="form-hb form-hb--newsletter" />
            </div>
        </div>
    </div>
}

export default Newsletter
